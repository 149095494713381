<template>
    <div class="mt-n6">
        <my-announcement class="div-my-announcement"></my-announcement>
    </div>
</template>

<script>
import MyAnnouncement from '../components/MyAnnouncement.vue';

export default {
    components: {
        MyAnnouncement
    }
};
</script>